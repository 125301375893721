import React, { type ReactNode, useEffect, useRef } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

export type AITelepointerWrapperProps = {
	children: ReactNode;
	hideTelepointer?: boolean;
};

const telepointerStyles = xcss({
	display: 'inline-block',
	position: 'absolute',
	width: '1.5px',
	height: '25px',
	background: 'linear-gradient(45deg, #F8E6A0 -12.02%, #8BDBE5 19.18%, #0C66E4 71.87%)',
	marginLeft: 'space.025',
	marginTop: 'space.negative.100',
	'::after': {
		content: '"AI"',
		position: 'absolute',
		display: 'block',
		top: 'space.0',
		left: 'space.0',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '10px',
		fontWeight: 'font.weight.bold',
		width: '12.5px',
		height: '13px',
		paddingTop: 'space.025',
		paddingLeft: 'space.025',
		lineHeight: 'initial',
		borderRadius: '0px 2px 2px 0px',
		color: 'color.text.inverse',
		background: 'linear-gradient(45deg, #8BDBE5 -57%, #0C66E4 71.87%)',
	},
});

const isAValidContainer = (node: ChildNode): boolean => {
	if (node.hasChildNodes() && node.lastChild?.nodeType !== Node.TEXT_NODE) {
		return true;
	}
	return false;
};

const getNestedLastChild = (originNode: ChildNode): Node => {
	if (isAValidContainer(originNode) && originNode.lastChild) {
		return getNestedLastChild(originNode.lastChild);
	} else if (originNode.textContent === '' && originNode.parentNode) {
		return originNode.parentNode;
	} else {
		return originNode;
	}
};

export const AITelepointerWrapper = ({
	hideTelepointer = false,
	children,
	...props
}: AITelepointerWrapperProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const telepointerRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (!hideTelepointer) {
			const renderer = containerRef.current!;

			if (renderer.lastChild && telepointerRef.current) {
				getNestedLastChild(renderer.lastChild).appendChild(telepointerRef.current);
			}
		}
	}, [children, hideTelepointer]);

	if (hideTelepointer) {
		return <>{children}</>;
	}

	return (
		<Box ref={containerRef}>
			<Box as="span" ref={telepointerRef} xcss={telepointerStyles} testId="ai-telepointer-cursor" />
			{children}
		</Box>
	);
};
